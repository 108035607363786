var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.deleteDialog"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.deletePrompt,
          },
          on: {
            cancel: function ($event) {
              _vm.selectedDialog = null
            },
            accept: function ($event) {
              return _vm.onDeleteDialog(_vm.selectedDialog.id)
            },
            close: function ($event) {
              _vm.selectedDialog = null
            },
            "update:active": function ($event) {
              _vm.deletePrompt = $event
            },
          },
        },
        [
          _vm.selectedDialog
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.selectedDialog.name))]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("vue.deleteDialogMessage")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.isValidCallCenterConnection
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-plus",
                      type: "filled",
                    },
                    on: { click: _vm.onAddDialog },
                  },
                  [_vm._v("Add Dialog")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.dialogs.length > 0
        ? _c(
            "div",
            { staticClass: "vx-row" },
            _vm._l(_vm.dialogs, function (dialog) {
              return _c(
                "div",
                {
                  key: dialog.id,
                  staticClass:
                    "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base",
                },
                [
                  _c(
                    "vx-card",
                    {
                      staticClass: "overflow-hidden dialog-container",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            justify: "flex-end",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { align: "left" } },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "rad-25",
                                  attrs: {
                                    color:
                                      dialog.type === "custom"
                                        ? "rgba(128, 128, 128, 0.5)"
                                        : "rgb(231, 154, 23)",
                                    type: "border",
                                    disabled: dialog.type === "custom",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.abTesting(dialog.id)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("routes.abtesting")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: { "margin-left": "auto" },
                              attrs: { align: "right" },
                            },
                            [
                              dialog.type !== "custom"
                                ? _c(
                                    "vs-switch",
                                    {
                                      attrs: { color: "#275D73" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updateDialogVisibility(
                                            dialog
                                          )
                                        },
                                      },
                                      model: {
                                        value: dialog.switchVisibility,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            dialog,
                                            "switchVisibility",
                                            $$v
                                          )
                                        },
                                        expression: "dialog.switchVisibility",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { slot: "on" }, slot: "on" },
                                        [_vm._v("On")]
                                      ),
                                      _c(
                                        "span",
                                        { attrs: { slot: "off" }, slot: "off" },
                                        [_vm._v("Off")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "flex-end",
                                  },
                                },
                                [
                                  _c(
                                    "vs-switch",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "danger",
                                        "vs-icon-on": "mobile_off",
                                        "vs-icon-off": "smartphone",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.disableInMobiles(dialog)
                                        },
                                      },
                                      model: {
                                        value: dialog.disableInMobiles,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            dialog,
                                            "disableInMobiles",
                                            $$v
                                          )
                                        },
                                        expression: "dialog.disableInMobiles",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { slot: "on" }, slot: "on" },
                                        [_vm._v(_vm._s(_vm.$t("vue.disabled")))]
                                      ),
                                      _c(
                                        "span",
                                        { attrs: { slot: "off" }, slot: "off" },
                                        [_vm._v(_vm._s(_vm.$t("vue.enabled")))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vx-tooltip",
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        text: _vm.$t(
                                          "info.disableDialogInMobile"
                                        ),
                                        position: "top",
                                      },
                                    },
                                    [_c("img", { attrs: { src: _vm.infoImg } })]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "p-6 dialog" }, [
                        dialog.type === "custom"
                          ? _c(
                              "div",
                              [
                                _c("feather-icon", {
                                  staticClass:
                                    "p-3 inline-flex rounded-full text-primary mb-4",
                                  style: {
                                    background: "rgba(var(--vs-primary),.15)",
                                  },
                                  attrs: { icon: "PlayCircleIcon" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                dialog.showVideo
                                  ? _c("feather-icon", {
                                      staticClass:
                                        "p-3 inline-flex rounded-full text-primary mb-4",
                                      style: {
                                        background:
                                          "rgba(var(--vs-primary),.15)",
                                      },
                                      attrs: { icon: "VideoIcon" },
                                    })
                                  : _c("feather-icon", {
                                      staticClass:
                                        "p-3 inline-flex rounded-full text-primary mb-4",
                                      style: {
                                        background:
                                          "rgba(var(--vs-primary),.15)",
                                      },
                                      attrs: { icon: "CameraIcon" },
                                    }),
                              ],
                              1
                            ),
                        _c("div", { staticClass: "truncate" }, [
                          _c("h2", { staticClass: "mb-1 font-bold" }, [
                            _vm._v(_vm._s(dialog.name)),
                          ]),
                          _c("span", [_vm._v(_vm._s(dialog.appearance))]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "middle" },
                        [
                          _c("feather-icon", {
                            staticClass:
                              "p-3 m-2 inline-flex rounded-full text-primary mb-4",
                            style: {
                              background: "rgba(var(--vs-primary),.15)",
                            },
                            attrs: { icon: "EditIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditDialog(dialog.id)
                              },
                            },
                          }),
                          _vm.dialogs.length < 100
                            ? _c("feather-icon", {
                                staticClass:
                                  "p-3 m-2 inline-flex rounded-full text-danger mb-4",
                                style: {
                                  background: "rgba(var(--vs-danger),.15)",
                                },
                                attrs: { icon: "Trash2Icon" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.selectedDialog = {
                                      id: dialog.id,
                                      name: dialog.name,
                                    }),
                                      (_vm.deletePrompt = true)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }