<template>
  <div>
    <vs-prompt
      :title="$t('vue.deleteDialog')"
      @cancel="selectedDialog = null"
      @accept="onDeleteDialog(selectedDialog.id)"
      @close="selectedDialog = null"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt" v-if="selectedDialog">
        <p>
          <strong>{{ selectedDialog.name }}</strong>
        </p>
        {{ $t('vue.deleteDialogMessage') }}
      </div>
    </vs-prompt>
    <div v-if="isValidCallCenterConnection" class="vx-row">
      <div class="vx-col w-full mb-2">
        <vs-button icon-pack="feather" icon="icon-plus" type="filled" @click="onAddDialog">Add Dialog</vs-button>
      </div>
    </div>
    <div v-if="dialogs.length > 0" class="vx-row">
      <div v-for="dialog in dialogs" :key="dialog.id" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <vx-card class="overflow-hidden dialog-container" style="cursor: pointer">
          <div style="display: flex; flex-direction: row; justify: flex-end">
            <div align="left">
              <vs-button
                :color="dialog.type === 'custom' ? 'rgba(128, 128, 128, 0.5)' : 'rgb(231, 154, 23)'"
                type="border"
                class="rad-25"
                :disabled="dialog.type === 'custom'"
                @click="abTesting(dialog.id)"
                >{{ $t('routes.abtesting') }}</vs-button
              >
            </div>
            <div align="right" style="margin-left: auto">
              <vs-switch color="#275D73" v-model="dialog.switchVisibility" @input="updateDialogVisibility(dialog)" v-if="dialog.type !== 'custom'">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
              <div style="display: flex; align-items: flex-end">
                <vs-switch
                  color="danger"
                  class="mt-2"
                  vs-icon-on="mobile_off"
                  vs-icon-off="smartphone"
                  v-model="dialog.disableInMobiles"
                  @input="disableInMobiles(dialog)"
                >
                  <span slot="on">{{ $t('vue.disabled') }}</span>
                  <span slot="off">{{ $t('vue.enabled') }}</span>
                </vs-switch>
                <vx-tooltip class="ml-2" :text="$t('info.disableDialogInMobile')" position="top">
                  <img :src="infoImg" />
                </vx-tooltip>
              </div>
            </div>
          </div>
          <div class="p-6 dialog">
            <div v-if="dialog.type === 'custom'">
              <feather-icon
                icon="PlayCircleIcon"
                class="p-3 inline-flex rounded-full text-primary mb-4"
                :style="{ background: `rgba(var(--vs-primary),.15)` }"
              ></feather-icon>
            </div>
            <div v-else>
              <feather-icon
                v-if="dialog.showVideo"
                icon="VideoIcon"
                class="p-3 inline-flex rounded-full text-primary mb-4"
                :style="{ background: `rgba(var(--vs-primary),.15)` }"
              ></feather-icon>
              <feather-icon
                v-else
                icon="CameraIcon"
                class="p-3 inline-flex rounded-full text-primary mb-4"
                :style="{ background: `rgba(var(--vs-primary),.15)` }"
              ></feather-icon>
            </div>
            <div class="truncate">
              <h2 class="mb-1 font-bold">{{ dialog.name }}</h2>
              <span>{{ dialog.appearance }}</span>
            </div>
          </div>

          <div class="middle">
            <feather-icon
              icon="EditIcon"
              @click="onEditDialog(dialog.id)"
              class="p-3 m-2 inline-flex rounded-full text-primary mb-4"
              :style="{ background: `rgba(var(--vs-primary),.15)` }"
            ></feather-icon>
            <feather-icon
              v-if="dialogs.length < 100"
              icon="Trash2Icon"
              @click=";(selectedDialog = { id: dialog.id, name: dialog.name }), (deletePrompt = true)"
              class="p-3 m-2 inline-flex rounded-full text-danger mb-4"
              :style="{ background: `rgba(var(--vs-danger),.15)` }"
            ></feather-icon>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      customerId: null,
      isValidCallCenterConnection: false,
      dialogs: [],
      deletePrompt: false,
      selectedDialog: null,
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    })
  },

  async created() {
    await this.getCustomerDialogs()
  },

  methods: {
    abTesting(dialogId) {
      this.$router.push(`/ab-testing/${dialogId}`)
    },
    async updateDialogVisibility(dialog) {
      await this.$db
        .collection('dialogs')
        .doc(dialog.id)
        .set({ visibility: dialog.switchVisibility ? 'always' : 'permission' }, { merge: true })
    },
    async disableInMobiles(dialog) {
      await this.$db.collection('dialogs').doc(dialog.id).set({ disableInMobiles: dialog.disableInMobiles }, { merge: true })
    },
    async updateDialogB2B(dialog) {
      const dialogRef = await this.$db.collection('dialogs').doc(dialog.id)
      await dialogRef.set({ b2bOnly: dialog.b2bOnly }, { merge: true })
    },
    async getAllDialogs(companyId) {
      const vm = this
      vm.$vs.loading()
      vm.dialogs = []
      const ref = await vm.$db.collection('dialogs').where('company', '==', companyId).get()
      ref.docs.forEach((doc) => {
        const dialog = doc.data()
        dialog.id = doc.id
        if (dialog.visibility === 'permission') {
          dialog.switchVisibility = false
        } else {
          dialog.switchVisibility = true
        }
        vm.dialogs.push({ ...dialog })
      })
      vm.$vs.loading.close()
    },

    async getCustomerDialogs() {
      await this.$vs.loading()

      this.customerId = this.$route.params.id

      const companyRef = await this.$db.collection('company').doc(this.activeUserInfo.company).get()
      const company = companyRef.data()

      if (company.isCallCenter) {
        const allowedCompanyQuerySnapshot = await this.$db
          .collection('call-center-settings')
          .where('callCenterId', '==', this.activeUserInfo.company)
          .where('customerId', '==', this.customerId)
          .where('approved', '==', 'confirmed')
          .get()

        if (!allowedCompanyQuerySnapshot.empty) {
          this.isValidCallCenterConnection = true

          await this.getAllDialogs(this.customerId)
        }
      }

      this.$vs.loading.close()
    },

    getDefaultDialogValues() {
      const dialog = {
        name: this.$i18n.t('vue.newDialog', 'en'),
        title: this.$i18n.t('vue.dialogTitleDefault', 'en'),
        text: this.$i18n.t('vue.popupTextDefault', 'en'),
        cancelButtonText: this.$i18n.t('vue.cancelButtonTextDefault', 'en'),
        confirmButtonText: this.$i18n.t('vue.confirmButtonTextDefault', 'en'),
        confirmButtonColor: '#238000',
        sloganTitle: "Let's Connect!",
        switchVisibility: true,
        visibleTimeout: 4,
        contactFormTimeout: 60,
        created: new Date(),
        showVideo: false,
        type: 'button-card',
        visibility: 'always',
        locale: this.$i18n.locale,
        createdBy: this.activeUserInfo.uid,
        dialogUpdated: new Date(),
        communicationOptions: {
          chat: true,
          audio: true,
          video: true
        },
        widgetUse: 'sales'
      }

      /* New Dialog Fields */
      dialog.isMoreInformationEnabled = false
      dialog.moreInformationButtonText = this.$i18n.t('vue.moreInformation', 'en')
      dialog.sloganTitle = "Let's Connect!"
      dialog.isAlwaysShowButtonEnabled = false
      dialog.isMinimizeSmallButtonEnabled = false
      dialog.isSmallButtonPreviewVisible = false
      dialog.showDefaultMedia = true
      dialog.useOneMediaForAllSteps = false
      dialog.useNewButtonPlusTextStyle = false
      dialog.buttonPlusTextNewMediaIconColor = '#3034F7'
      dialog.allMedia = null
      dialog.buttonMedia = null
      dialog.smallButtonMedia = null
      dialog.welcomeScreenMedia = null
      dialog.buttonPlusTextNewMedia = null
      dialog.smallButtonImage = null

      dialog.textStep2 = this.$i18n.t('vue.textStep2DefaultText', 'en')
      dialog.step2ButtonColor = '#3B86F7'
      dialog.step2TextColor = '#ffffff'
      dialog.waitingRoomMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')

      dialog.waitingRoomContentChatMessage = {
        backgroundColor: '#C4C4C4',
        fontColor: '#12598d'
      }

      dialog.sessionEndScreen = {
        titleClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en'),
        copyClosingMessage: this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF'
      }
      dialog.contactForm = {
        message: this.$i18n.t('vue.contactFormText', 'en'),
        backgroundColor: '#3B86F7',
        fontColor: '#FFFFFF',
        isBookingEnabled: false,
        selectedAppointment: null,
        fields: {
          name: {
            enabled: true,
            required: true
          },
          email: {
            enabled: true,
            required: true
          },
          phone: {
            enabled: true,
            required: true
          },
          message: {
            enabled: true,
            required: true
          },
          date: {
            enabled: false,
            required: false
          }
        },
        sendMessageTitle: this.$i18n.t('vue.sendMessageDefaultTitle', 'en'),
        sendMessageCopy: this.$i18n.t('vue.sendMessageDefaultCopy', 'en'),
        bookingSlotTitle: this.$i18n.t('vue.bookingSlotDefaultTitle', 'en'),
        bookingSlotCopy: this.$i18n.t('vue.bookingSlotDefaultCopy', 'en'),
        isPrivacyInformationEnabled: false,
        isDefaultContactFormInsideChat: false,
        privacyInformationHtml: ''
      }
      /* New Dialog Fields */

      dialog.companyDisplayName = this.company && this.company.companyDisplayName ? this.company.companyDisplayName : null

      return dialog
    },
    async onAddDialog() {
      const vm = this
      vm.$vs.loading()

      const dialog = this.getDefaultDialogValues()

      dialog.company = this.customerId
      const dialogRef = await vm.$db.collection('dialogs').add(dialog)
      dialog.id = dialogRef.id
      vm.dialogs.unshift(dialog)
      vm.$vs.loading.close()
    },
    onEditDialog(id) {
      this.$router.push(`/web-connect/${id}`)
    },
    async onDeleteDialog(id) {
      const vm = this
      vm.$vs.loading()
      await vm.$db.collection('dialogs').doc(id).delete()
      vm.dialogs = vm.dialogs.filter((x) => x.id !== id)
      vm.dialogs = vm.dialogs.filter((x) => x.id !== id)
      vm.$vs.loading.close()
    }
  }
}
</script>

<style scoped>
.dialog-container {
  position: relative;
  min-height: 236.5px;
}
.middle {
  transition: 0.5s ease;
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.dialog {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.dialog-container:hover .dialog {
  opacity: 0.3;
}
.dialog-container:hover .middle {
  opacity: 1;
}
.dialog-container:hover .rad-25 {
  opacity: 1;
}
.rad-25 {
  border-radius: 25px;
  opacity: 0.3;
}
</style>
